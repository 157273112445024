import {createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw} from 'vue-router'
import {useStore} from 'vuex'
import MainPageLayout from '@/layout/MainPageLayout.vue'
import request from '@/utils/request'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    alias: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  }, {
    path: '/keywords',
    name: 'Keywords',
    component: () => import('../layout/MainPageLayout.vue'),
    children: [
      {
        path: 'profile',
        name: 'BasicProfile',
        component: () => import('../views/BasicProfile.vue'),
        meta: {
          key: 'basic'
        }
      },
      {
        path: 'users',
        name: 'UserManage',
        component: () => import('../views/UserManage.vue'),
        meta: {
          key: 'basic'
        }
      },
      {
        path: 'directors',
        name: 'directors',
        component: () => import('../views/DirectorManage.vue'),
        meta: {
          key: 'basic'
        }
      },
      {
        path: 'bloggers',
        name: 'BloggerManage',
        component: () => import('../views/BloggerManage.vue'),
        meta: {
          key: 'basic'
        }
      },
      {
        path: 'accounts',
        name: 'Account',
        component: () => import('../views/Account.vue'),
        meta: {
          key: 'basic'
        }
      },
      {
        path: 'bank_cards',
        name: 'BankCards',
        component: () => import('../views/payment/BankCards.vue'),
        meta: {
          key: 'basic'
        }
      },
      {
        path: 'platforms',
        name: 'Platforms',
        component: () => import('../views/Platforms.vue'),
        meta: {
          key: 'basic'
        }
      },
      {
        path: 'manage',
        name: 'KeywordManage',
        component: () => import('../views/KeywordManage.vue'),
        meta: {
          key: 'keywords'
        }
      },
      {
        path: 'apply',
        name: 'KeywordApply',
        component: () => import('../views/KeywordManage.vue'),
        meta: {
          key: 'keywords'
        }
      },
      {
        path: 'stats_keyword',
        name: 'StatsKeyword',
        component: () => import('../views/StatsKeyword.vue'),
        meta: {
          key: 'stats'
        }
      },
      {
        path: 'stats_keyword_sum',
        name: 'StatsKeywordSum',
        component: () => import('../views/StatsKeywordSum.vue'),
        meta: {
          key: 'stats'
        }
      },
      {
        path: 'stats_data_blogger',
        name: 'StatsDataBlogger',
        component: () => import('../views/StatsDataBlogger.vue'),
        meta: {
          key: 'stats'
        }
      },
      {
        path: 'stats_data_director_daily',
        name: 'StatsDataDirectorDaily',
        component: () => import('../views/StatsDataDirectorDaily.vue'),
        meta: {
          key: 'stats'
        }
      },
      {
        path: 'stats_data_director',
        name: 'StatsDataDirector',
        component: () => import('../views/StatsDataDirector.vue'),
        meta: {
          key: 'stats'
        }
      },
      {
        path: 'stats_data_director_manager',
        name: 'StatsDataDirectorManager',
        component: () => import('../views/StatsDataDirectorManager.vue'),
        meta: {
          key: 'stats'
        }
      },
      {
        path: 'stats_newer_platform',
        name: 'StatsNewerPlatform',
        component: () => import('../views/StatsNewerPlatform.vue'),
        meta: {
          key: 'stats'
        }
      },
      {
        path: 'stats_newer_platform_sum',
        name: 'StatsNewerPlatformSum',
        component: () => import('../views/StatsNewerPlatformSum.vue'),
        meta: {
          key: 'stats'
        }
      },

      {
        path: 'coreboard',
        name: 'Coreboard',
        component: () => import('../views/Coreboard.vue'),
        meta: {
          key: 'stats'
        }
      },
      {
        path: 'stats_data_upload',
        name: 'StatsDataUpload',
        component: () => import('../views/StatsDataUpload.vue'),
        meta: {
          key: 'stats'
        }
      },
      {
        path: 'audit_record',
        name: 'AuditRecord',
        component: () => import('../views/AuditRecord.vue'),
        meta: {
          key: 'stats'
        }
      },
      {
        path: 'notification',
        name: 'Notification',
        component: () => import('../views/Notification.vue'),
        meta: {
          key: 'notification'
        }
      },
      {
        path: 'op_notification',
        name: 'OpNotification',
        component: () => import('../views/OpNotification.vue'),
        meta: {
          key: 'notification'
        }
      },
      {
        path: 'banner',
        name: 'Banner',
        component: () => import('../views/operate/Banner.vue'),
        meta: {
          key: 'operate'
        }
      },
      {
        path: 'popups',
        name: 'Popups',
        component: () => import('../views/operate/Popups.vue'),
        meta: {
          key: 'operate'
        }
      },
      {
        path: 'activity',
        name: 'Activity',
        component: () => import('../views/operate/Activity.vue'),
        meta: {
          key: 'operate'
        }
      },
      {
        path: 'member',
        name: 'Member',
        component: () => import('../views/operate/Member.vue'),
        meta: {
          key: 'operate'
        }
      },
      {
        path: 'invitation',
        name: 'Invitation',
        component: () => import('../views/operate/Invitation.vue'),
        meta: {
          key: 'operate'
        }
      },
      {
        path: 'platform_config',
        name: 'PlatformIndexConfig',
        component: () => import('../views/config/IndexConfig.vue'),
      },
      {
        path: 'director_core/director_set',
        name: 'OrganizeDirectorSet',
        component: () => import('../views/organize/DirectorSet.vue'),
        meta: {
          key: 'relatives-director'
        }
      }, {
        path: 'director_core/director',
        name: 'OrganizeDirector',
        component: () => import('../views/organize/Director.vue'),
        meta: {
          key: 'relatives-director'
        }
      }, {
        path: 'director_core/stats',
        name: 'OrganizeStats',
        component: () => import('../views/organize/Stats.vue'),
        meta: {
          key: 'relatives-director'
        }
      }, {
        path: 'director_core/blogger',
        name: 'OrganizeBlogger',
        component: () => import('../views/organize/Blogger.vue'),
        meta: {
          key: 'relatives-director'
        }
      }, {
        path: 'manager_leader/leader_set',
        name: 'OrganizeLeaderSet',
        component: () => import('../views/organize/LeaderSet.vue'),
        meta: {
          key: 'relatives-leader'
        }
      }, {
        path: 'manager_leader/bound',
        name: 'OrganizeConfigure',
        component: () => import('../views/organize/Configure.vue'),
        meta: {
          key: 'relatives-leader'
        }
      }, {
        path: 'manager_leader/summary',
        name: 'OrganizeLeader',
        component: () => import('../views/organize/Leader.vue'),
        meta: {
          key: 'relatives-leader'
        }
      }, {
        path: 'manager_leader/director',
        name: 'OrganizeLeaderManager',
        component: () => import('../views/organize/LeaderManager.vue'),
        meta: {
          key: 'relatives-leader'
        }
      }, {
        path: 'payment_platforms',
        name: 'PaymentPlatforms',
        component: () => import('../views/payment/PaymentPlatforms.vue'),
        meta: {
          key: 'payment'
        }
      }, {
        path: 'payment_manage',
        name: 'PaymentManage',
        component: () => import('../views/payment/PaymentManage.vue'),
        meta: {
          key: 'payment'
        }
      }, {
        path: 'incomes_detail',
        name: 'IncomesDetail',
        component: () => import('../views/payment/IncomesDetail.vue'),
        meta: {
          key: 'payment'
        }
      }, {
        path: 'payment_batches',
        name: 'PaymentBatches',
        component: () => import('../views/payment/PaymentBatches.vue'),
        meta: {
          key: 'payment'
        }
      }, {
        path: 'payment_detail',
        name: 'PaymentDetail',
        component: () => import('../views/payment/PaymentDetail.vue'),
        meta: {
          key: 'payment'
        }
      }, {
        path: 'settlement_rules',
        name: 'SettlementRules',
        component: () => import('../views/settlement/SettlementRules.vue'),
        meta: {
          key: 'settlement'
        }
      }, {
        path: 'settlement_rules_create',
        name: 'SettlementCreateRules',
        component: () => import('../views/settlement/components/SettlementCreateRules.vue'),
        meta: {
          key: 'settlement'
        }
      }, {
        path: 'settlement_profit',
        name: 'SettlementProfit',
        component: () => import('../views/settlement/SettlementProfit.vue'),
        meta: {
          key: 'settlement'
        }
      }, {
        path: 'settlement_payment',
        name: 'SettlementPayment',
        component: () => import('../views/settlement/SettlementPayment.vue'),
        meta: {
          key: 'settlement'
        }
      },{
        path: 'settlement_uploadprofit',
        name: 'UploadProfit',
        component: () => import('../views/settlement/components/UploadProfit.vue'),
        meta: {
          key: 'settlement'
        }
      },{
        path: 'settlement_createpayment',
        name: 'CreatePayment',
        component: () => import('../views/settlement/components/CreatePayment.vue'),
        meta: {
          key: 'settlement'
        }
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('../views/Coreboard.vue'),

        meta: {
          key: 'stats'

        },
      },
      {
        path: 'home',
        name: 'Home',
        component: () => import('../views/Dashboard/Index.vue'),
        meta: {
          key: 'home'
        },
      },
    ]
  },
  {
    path: '/keywords/test',
    name: 'Test',
    component: () => import('../views/Test.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {
      isAuth: false,
    },
  },
  {
    path: '/:catchAll(.*)', // 404 页面
    redirect: '/404',
    meta: {
      isAuth: false,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
