<template>
  <a-config-provider
    :locale="zhCN"
    :theme="{
      token: {
        colorPrimary: '#275BFF',
        borderRadius: 4,
      },
    }"
  >
    <a-style-provider :transformers="[legacyLogicalPropertiesTransformer]" hash-priority="high">
      <router-view />
    </a-style-provider>
  </a-config-provider>
</template>

<script lang="ts" setup>
import {useRouter,useRoute} from 'vue-router'
import { useStore } from 'vuex'
import {ref,onMounted} from 'vue'
import request from '@/utils/request'
import { legacyLogicalPropertiesTransformer } from 'ant-design-vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

const router = useRouter()
const route = useRoute()
const store = useStore()

onMounted(() => {
  request.get(
    '/api/users/auth'
  ).then((res: any) => {
    store.commit("setLogin", res.data)
    // if (route.path == "/" || route.path == '/login') {
    //   router.push({
    //     path: "/keywords/apply"
    //   })
    // }
  }).catch((res) => {
    console.log("未登录", res)
    router.push({
      path: "/login"
    })
  })

  request.get(
    '/api/platforms'
  ).then((res: any) => {
    console.log('平台', res.data.platforms);
    
    let data = res.data?.platforms?.filter(v=>v.status==1)
    let platform = []
    data.forEach((v:any)=>{
      platform.push({value:v.platform,label:v.platform})
    })
    console.log('platform', platform);
    
    store.commit("setPlatforms", platform)
    store.commit("setPlatformsData", data)
  }).catch((err) => {
    console.log('err', err);
    
  })
})

</script>

<style>
#webpack-dev-server-client-overlay {
  display: none !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

.ant-layout-header {
  background: #21205b !important;
}
.ant-layout-sider {
  background: #21205b !important;
}
.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub  {
  background: #21205b !important;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.notice-icon {
  width: 18px;
  height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #000;
  border-radius: 50%;
  margin-left: 5px;
  cursor: pointer;
}
</style>
