import axios from 'axios';
import {useRouter} from 'vue-router'
import { notification } from 'ant-design-vue';

const instance = axios.create({
  timeout: 60000,
});

const processError = (origin: any): boolean =>{
 
  const rsp = origin.response ? origin.response : origin

  if (typeof rsp.data  == 'string') {
    notification.error({
      message: `${rsp.status} ${rsp.statusText}`,
      description: rsp.data
    })
    return true
  }
  if (rsp && rsp.data && rsp.data.error) {
    const _d = rsp.data
    notification.error({
      message: _d.error.code,
      description: _d.error.message
    })
    return true
  } else if (rsp.message) {
    notification.error({
      message: "Unexpect Error",
      description: rsp.message
    })
    return true
  } 
  return false
}


export default {
  get: async (path:string, query: any = {}) => {
    return new Promise((resolve, reject) => {
      const router = useRouter()
      instance.get(
        path, {
            params: query
        }
      ).then(rsp => {
        if (rsp.status == 401) {
          
          router.push({
              path: "/"
          })
          return
        }
        processError(rsp)
        resolve(rsp.data)
      }).catch(err => {
        processError(err)
        reject(err)
      })
          
    })
  },
  post: (path: string, body: any = {}, successTip = "操作成功") => {
    
    return new Promise((resolve, reject) => {
      const router = useRouter()
      instance.post(
        path, body
      ).then(rsp => {
        
        if (!processError(rsp)) {
          // notification.success({
          //   message: successTip,
          // })

          if (typeof rsp.data  == 'string') {
            notification.success({
              message: successTip,
            })
          } else {
            notification.success({
              message: "操作成功",
            })
          }
        }
        resolve(rsp.data)
      }).catch(err => {

        if (err.response?.config?.url=='/api/login'&&err.response?.status==401&&err.response?.data?.error?.code==100101) {
          notification.success({
            message: '验证码已发送',
          })
          resolve(err.response.data.error)
        } else {
          processError(err)
          reject(err)
        }
      })
          
    })
  },
  postFile: (path: string, body: any = {}, successTip = "操作成功") => {
    
    return new Promise((resolve, reject) => {
      const router = useRouter()
      instance.post(
        path, body, {
          responseType: 'blob',
        }
      ).then(rsp => {
        console.log('下载', rsp)
        notification.success({
          message: "操作成功",
        })
        resolve(rsp.data)
      }).catch(err => {
        processError(err)
        reject(err)
      })
          
    })
  },
  put: (path: string, body: any = {},  successTip = "操作成功") => {
    return new Promise((resolve, reject) => {
      const router = useRouter()
      instance.put(
        path, body
      ).then(rsp => {
        if (!processError(rsp)) {
          notification.success({
            message: successTip,
          })
        }
        resolve(rsp.data)
      }).catch(err => {
        processError(err)
        reject(err)
      })
          
    })
  },
  delete: async (path:string, query: any = {},  successTip = "操作成功") => {
    return new Promise((resolve, reject) => {
      const router = useRouter()
      instance.delete(
        path, {
            params: query
        }
      ).then(rsp => {
        if (!processError(rsp)) {
          notification.success({
            message: successTip,
          })
        }
        resolve(rsp.data)
      }).catch(err => {
        processError(err)
        reject(err)
      })
          
    })
  },
  upload: (path: string, file: File, successTip = "上传成功") => {
    const forms = new FormData()
    const configs = {
      headers:{'Content-Type':'multipart/form-data'}
    };
    forms.append('file', file)

    return new Promise((resolve, reject) => {
      const router = useRouter()
      instance.post(
        path, 
        forms,
        configs
      ).then(rsp => {
        if (!processError(rsp)) {
          notification.success({
            message: successTip,
          })
        }
        resolve(rsp.data)
      }).catch(err => {
        processError(err)
        reject(err)
      })    
    })
  },
  uploadFile: (path: string, file: File, successTip = "上传成功") => {
    // const forms = new FormData()
    const configs = {
      headers:{'Content-Type':'multipart/form-data'}
    };
    // forms.append('file', file)

    const forms = file

    return new Promise((resolve, reject) => {
      const router = useRouter()
      instance.post(
        path, 
        forms,
        configs
      ).then(rsp => {
        if (!processError(rsp)) {
          notification.success({
            message: successTip,
          })
        }
        resolve(rsp.data)
      }).catch(err => {
        processError(err)
        reject(err)
      })    
    })
  }
}